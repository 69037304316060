import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFireCurrentWeather, IFireForecastedWeather, IFireWeatherParam, IFireNameLocation, IFireDetailAlert, IFireComments, IDuplicateFire, IDuplicateFireCoords } from "api/models";
import { getCurrentWeather, getForecastedWeather, uploadKmlKmz, saveFire, getResFire, getDuplicateFire, checkFireNavigation, getSharedKey } from "api/fdeAPI";
import { getPerimeterByFire } from "../../api/smokeCheckAPI";
import { getArea } from "utils/geogHelper";

/** Slice for add/update fire */

//define the state for new Fire
export interface FireState {
    isAddFire: boolean;
    fireId?: number;
    fireNameLocation: IFireNameLocation;
    fireDetailAlert: IFireDetailAlert;
    fireComments: IFireComments;
    currentWeather: IFireCurrentWeather;
    forecastedWeather: IFireForecastedWeather;
    windDirections: string[];
    isValid: boolean;
    duplicateFire: IDuplicateFire;
    invalidKML: string;
    //map
    expandMapArea: boolean;
    //Original fire perimeter
    orgFirePerimeter: any;
    //Fire perimeter in fde page
    firePerimeter: any;
    //Fire perimeter in editing
    drawFirePerimeter: any;
    fireLocation: any;
    allPreviousPerimeter: any;
    selectPreviousPerimeter: any;
    radiusFromSize: boolean;
    drawPerimeterError: boolean;
    autoFocusPercent: any;
    labelUpdate: string;
}

const initialState: FireState = {
    isAddFire: true,
    fireId: 0,
    fireNameLocation: { fireName: "", alternateName: "", city: "", state: "AK", lat: "", lon: "", fireSize: "", kmlFileName: "", isNoteworthyFire: false, containment: '' },
    fireDetailAlert: { fuelType: [], alertDistance: 5, percentContained: '' },
    fireComments: { suppressionComment: "", clientComment: "" },
    currentWeather: { temperature: "", windSpeed: "", gusting: "", windDirection: "", precipitation: "", humidity: "", redFlag: false, weatherRating: "Moderate" },
    forecastedWeather: { fxTemperature: "", fxWindSpeed: "", fxGusting: "", fxWindDirection: "", fxPrecipitation: "", fxHumidity: "", fxWeatherRating: "Moderate", fxTime: "Today" },
    windDirections: ["", "N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
    isValid: true,
    duplicateFire: { name: "", monitoredDate: "", fireId: "" },
    invalidKML: '',
    expandMapArea: false,
    orgFirePerimeter: null,
    firePerimeter: null,
    drawFirePerimeter: null,
    fireLocation: null,
    allPreviousPerimeter: null,
    selectPreviousPerimeter: null,
    radiusFromSize: true,
    drawPerimeterError: false,
    autoFocusPercent: null,
    labelUpdate: '',
}

export const fetchResFirePerimeter = createAsyncThunk('actions/fetchResFirePerimeter', async (fireId: number) => {
    let response = await getPerimeterByFire(fireId);
    return response || {};
});

export const fetchCurrentWeather = createAsyncThunk('actions/fetchCurrentWeather', async (weatherParam: Partial<IFireWeatherParam>) => {
    let response = await getCurrentWeather(weatherParam);
    return response || {};
});

export const fetchForecastedWeather = createAsyncThunk('actions/fetchForecastedWeather', async (weatherParam: Partial<IFireWeatherParam>) => {
    let response = await getForecastedWeather(weatherParam);
    return response || {};
});

export const fetchDuplicateFire = createAsyncThunk('actions/fetchDuplicateFire', async (coords: Partial<IDuplicateFireCoords>) => {
    let response = await getDuplicateFire(coords);
    return response || {};
});

export const fetchResFire = createAsyncThunk('actions/fetchResFire', async (fireId: number) => {
    let response = await getResFire(fireId);
    return response || {};
});

export const uploadGISKmlKmz = createAsyncThunk("actions/uploadKmlKmz", async (formData: FormData) => {
    return uploadKmlKmz(formData);
});

export const getAZSharedKey = () => {
    return getSharedKey();
}

export const saveFireAction = createAsyncThunk('actions/saveFire', async (fireSaveViewModel: any) => {
    let fireId = await saveFire(fireSaveViewModel);
    let response = await checkFireNavigation(fireId);
    return response || {};
});

/** State slice with reducers, link reducer and action */
export const fireSlice = createSlice({
    name: "fire",
    initialState,
    reducers: {
        setIsAddFire: (state, action) => {
            state.isAddFire = action.payload;
            if (state.isAddFire) {
                state.labelUpdate = '';
            } else {
                state.labelUpdate = 'Update '
            }
        },

        setFireId: (state, action) => {
            state.fireId = action.payload;
        },

        clearData: (state) => {

            if (state.fireId != undefined && state.fireId > 0) {
                state.fireNameLocation.alternateName = '';
                state.fireNameLocation.fireSize = '';
                state.fireNameLocation.kmlFileName = '';
                state.firePerimeter = { ...state.orgFirePerimeter };
            }
            else {
                state.fireNameLocation = initialState.fireNameLocation;
                state.firePerimeter = initialState.firePerimeter;
            }

            state.fireDetailAlert = initialState.fireDetailAlert;
            state.currentWeather = initialState.currentWeather;
            state.forecastedWeather = initialState.forecastedWeather;
            state.fireComments = initialState.fireComments;
            state.drawFirePerimeter = initialState.drawFirePerimeter;

        },
        setCurrentWeather: (state, action) => {
            state.currentWeather = action.payload as IFireCurrentWeather;
        },
        setForecastedWeather: (state, action) => {
            state.forecastedWeather = action.payload as IFireForecastedWeather;
        },
        setDuplicateFire: (state, action) => {
            state.duplicateFire = action.payload as IDuplicateFire;
        },
        setFireComments: (state, action) => {

            state.fireComments = action.payload as IFireComments;
        },
        setFireNameLocation: (state, action) => {
            state.fireNameLocation = action.payload as IFireNameLocation;
        },
        setFireDetailAlert: (state, action) => {
            state.fireDetailAlert = action.payload as IFireDetailAlert;
        },
        setLat: (state, action) => {
            state.fireNameLocation.lat = action.payload;
        },
        setLong: (state, action) => {
            state.fireNameLocation.lon = action.payload;
        },
        setIsValid: (state, action) => {
            state.isValid = action.payload;
        },
        setInvalidKML: (state, action) => {
            state.invalidKML = action.payload;
        },
        setExpandMapArea: (state, action) => {
            state.expandMapArea = action.payload;
        },
        setFirePerimeter: (state, action) => {
            state.firePerimeter = action.payload;
        },
        setDrawFirePerimeter: (state, action) => {
            state.drawFirePerimeter = action.payload;
        },
        setFireLocation: (state, action) => {
            state.fireLocation = action.payload;
        },
        setRadiusFromSize: (state, action) => {
            state.radiusFromSize = action.payload;
        },
        setAllPreviousPerimeter: (state, action) => {
            state.allPreviousPerimeter = action.payload;
        },
        setDrawPerimeterError: (state, action) => {
            state.drawPerimeterError = action.payload;
        },
        setSelectPreviousPerimeter: (state, action) => {
            state.selectPreviousPerimeter = action.payload;
        },
        setAutoFocusPercent: (state, action) => {
            state.autoFocusPercent = action.payload;
        },
    },
    extraReducers: builder => {
        //fetchCurrentWeather
        builder
            .addCase(fetchCurrentWeather.fulfilled, (state, action) => {
                state.currentWeather = action.payload;
            })
            .addCase(fetchForecastedWeather.fulfilled, (state, action) => {
                state.forecastedWeather = action.payload;
            })
            .addCase(fetchDuplicateFire.fulfilled, (state, action) => {
                state.duplicateFire = action.payload;
            })
            .addCase(fetchResFire.fulfilled, (state, action) => {
                let fireInfoUpdate = action.payload;
                //If it is an integer, coordinate verification fails, so add at least one decimal place
                var lat = fireInfoUpdate.fireNameLocation.lat + '';
                var lon = fireInfoUpdate.fireNameLocation.lon + '';
                if (lat.length > 0 && lat.indexOf('.') < 0)
                    fireInfoUpdate.fireNameLocation.lat += '.0';
                if (lon.length > 0 && lon.indexOf('.') < 0)
                    fireInfoUpdate.fireNameLocation.lon += '.0';
                state.fireNameLocation = fireInfoUpdate.fireNameLocation;
                state.fireDetailAlert = fireInfoUpdate.fireDetailAlert;
            })
            .addCase(saveFireAction.fulfilled, (state, action) => {
                let result = action.payload;
                state.fireId = result.fireId;
                // Uncomment out the below after dark release of new fire page
                // window.location.href = "/fire/" + state.fireId;
                window.location.href = "/firemap/" + state.fireId;
            })
            .addCase(uploadGISKmlKmz.fulfilled, (state, action) => {
                let fireResponse = action.payload;

                if (fireResponse?.geometry) {
                    //fire perimeter
                    state.firePerimeter = { ...fireResponse, properties: { type: 'perimeter' } };
                    state.radiusFromSize = false;
                    state.fireNameLocation.fireSize = getArea(fireResponse.geometry, true);
                }

                state.fireNameLocation.kmlFileName = fireResponse?.properties?.kmlFileName;
                state.fireNameLocation.lat = fireResponse?.properties?.lat;
                state.fireNameLocation.lon = fireResponse?.properties?.lon;
                if (fireResponse.error) {
                    state.invalidKML = fireResponse.error;
                }
            })
            .addCase(fetchResFirePerimeter.fulfilled, (state, action) => {
                let resFirePerimeter = action.payload
                //Only store fire perimeter
                if (resFirePerimeter?.features?.length) {
                    let firePerimeter = resFirePerimeter.features.filter(f => f?.properties?.type === 'perimeter')
                    if (firePerimeter?.length) {
                        state.orgFirePerimeter = { ...firePerimeter[0] };
                        state.firePerimeter = { ...firePerimeter[0] };
                    }
                } else {
                    state.orgFirePerimeter = {};
                    state.firePerimeter = {}
                }
            });
    }
});

/** Action creators are generated for each case reducer function */
export const {
    setIsAddFire,
    setFireId,
    clearData,
    setCurrentWeather,
    setForecastedWeather,
    setDuplicateFire,
    setFireComments,
    setFireNameLocation,
    setFireDetailAlert,
    setLat,
    setLong,
    setIsValid,
    setInvalidKML,
    setExpandMapArea,
    setFirePerimeter,
    setDrawFirePerimeter,
    setFireLocation,
    setRadiusFromSize,
    setAllPreviousPerimeter,
    setSelectPreviousPerimeter,
    setDrawPerimeterError,
    setAutoFocusPercent
} = fireSlice.actions;

export default fireSlice.reducer;

