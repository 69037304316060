import { useEffect, useMemo } from "react";
import { CommandButton, DefaultButton, DirectionalHint, Icon, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";

import { INavigationGroup, Navigation } from "components/common/Navigation";
import logo from "assets/img/wds-logo-no-gloss-no-text.png";
import { useAppSelector, useAuthContext } from "app/hooks";
import { SIGNOUT_PATH } from "utils/consts";
import personLogo from "assets/img/person-logo.png";
import menuIcon from "assets/img/menu.png";
import { Constants } from "utils/constants";

export default function Header() {
    const { title } = useAppSelector(state => state.global);
    const { site, role, isInternalUser } = useAuthContext();

    const navLinkGroups: INavigationGroup[] = useMemo(() => {
        let groups: INavigationGroup[] = [];

        // Fire
        const fireItems: any[] = []
        if (Constants.AppRoles.allRead.includes(role)) {
            fireItems.push({ key: 'SitMap', text: 'Situation Map', href: '/sitmap' });
        }
        if (Constants.AppRoles.allRead.includes(role) && isInternalUser) {
            fireItems.push({ key: 'NewFire', text: 'New Fire', href: '/addfire' });
            fireItems.push({ key: 'FireScraper', text: 'Fire Scraper', href: '/monitoringheadquarters'});
        }
        if (fireItems.length) {
            groups.push({ text: 'Fire', key: 'Fire', items: fireItems })
        }

        // Property        
        const propItems: any[] = []
        if (Constants.AppRoles.allRead.includes(role) && isInternalUser) {
            propItems.push({ key: 'PHA', text: 'PHA', href: '/pha' });
        }
        if (Constants.AppRoles.allRead.includes(role)) {
            propItems.push({ key: 'Property', text: 'Property Search', href: '/propertysearch' });
        }
        if (propItems.length) {
            groups.push({ text: 'Property', key: 'Property', items: propItems })
        }

        // Report        
        const reportItems: any[] = []
        if (Constants.AppRoles.allRead.includes(role)) {
            reportItems.push({ key: 'ProgramUse', text: 'Program Use', href: '/programUse' });
        }
        if (Constants.AppRoles.allRead.includes(role)) {
            reportItems.push({ key: 'HistoricFires', text: 'Historic Fires', href: '/historicFires' });
        }
        if (reportItems.length) {
            groups.push({ text: 'Report', key: 'Report', items: reportItems })
        }

        // Resources
        const resourcesItems: any[] = [];
        if (Constants.AppRoles.allRead.includes(role) && isInternalUser) {
            resourcesItems.push({ key: 'Resource Status', text: 'Resource Status', href: '/resourcestatus' });
            resourcesItems.push({ key: 'ResourceScheduling', text: 'Resource Scheduling', href: '/resourceScheduling' });
            resourcesItems.push({ key: 'EngineTime', text: 'Engine Time', href: '/timeEngine' });

        }
        if(resourcesItems.length) {
            groups.push({ text: 'Resources', key: 'Resources', items: resourcesItems })
        }
        
        // IMPORTANT NOTICE: PLEASE DO NOT ADD ANY MENU TO THE RIGHT OF THE ADMIN MENU
        // Admin
        const adminItems: any[] = []
        if (['Admin', 'Manager'].includes(role) && isInternalUser) {
            adminItems.push({ key: 'Api Management', text: 'Api Management', href: '/apiManagement' });
        }
        if (['Admin', 'Manager'].includes(role) && isInternalUser) {
            adminItems.push({ key: 'User Management', text: 'User Management', href: '/admin/users' });
        }
        if (adminItems.length) {
            groups.push({ text: 'Admin', key: 'Admin', items: adminItems })
        }
        if (adminItems.length) {
            adminItems.push({ text: 'Client Subscription', key: 'Client Subscription', href: '/clientSubscription' })
        }
        // IMPORTANT NOTICE: PLEASE DO NOT ADD ANY MENU TO THE RIGHT OF THE ADMIN MENU
        return groups;
    }, [site, role]);
    const menu: IContextualMenuProps = useMemo(() => {
        const items = (navLinkGroups || []).map<IContextualMenuItem>(group => ({
            text: group.text,
            key: group.key,
            subMenuProps: {
                items: group.items || []
            }
        }));
        items.push({ key: 'signout', text: 'Sign Out', href: SIGNOUT_PATH });

        return {
            items,
            directionalHint: DirectionalHint.bottomRightEdge
        };
    }, [navLinkGroups]);
    const tabletMenu = useMemo(() => {
        return <div className="mb-menu">
            <DefaultButton menuProps={menu} className="hamburger-button">
                <img src={menuIcon} />
            </DefaultButton>
        </div>;
    }, [menu])

    const { isAuthenticated, name, email } = useAuthContext();
    const actions: IContextualMenuProps = useMemo(() => ({
        items: [
            {
                key: 'profile',
                text: 'My Profile',
                href: '/profile'
            },
            {
                key: 'signout',
                text: 'Sign Out',
                onClick: () => {
                    window.location.href = SIGNOUT_PATH;
                }
            }
            
        ],
        directionalHint: DirectionalHint.bottomRightEdge
    }), []);
    const userInfo = useMemo(() => {
        return isAuthenticated && email ? (
            <div className="person-logo">
                <Icon className="person-icon" imageProps={{ src: personLogo }} />
                <CommandButton className="user-action" text={name} menuProps={actions} title={name} />
            </div>
        ) : null;
    }, [isAuthenticated, email, name, actions]);

    const onWDSLogoClick = () => {
        if(isInternalUser){
            window.location.href = "/workflowmanagement";
        } else
        {
            window.location.href = "/sitmap";
        }
    }

    useEffect(() => {
        title && (document.title = title);
    }, [title]);
    return (
        <div className="header">
            <div className="bannertoprow d-flex">
                <div className="wdslogo" onClick={onWDSLogoClick}><img src={logo} alt="logo" /></div>
                <div className="wdstitle" onClick={onWDSLogoClick}>WILDFIRE DEFENSE SYSTEMS</div>
                <Navigation groups={navLinkGroups} />
                {userInfo}
                {tabletMenu}
            </div>
        </div>
    );
}