import { getJSON } from "jquery";
import { postJson, postJsonWithNoTrack } from "../utils/index";
import { IFireName, IProgramFire, IFireDetail, IFireNavigation, IFirePerimeter, IClient, IActiveFire } from "./models";
import { FeatureCollection } from "@turf/turf";

/*subnav fire --begin*/
export interface ISubNavFire {
  fireID: number;
  name: string;
}
//sitmap top subnav firelist
export async function getSubNavFires(count: number): Promise<ISubNavFire[]> {
  if (isNaN(count)) {
    count = 5;
  }
  return postJson("/api/sitmap/fires/subnav", { pagination: { pageSize: count, pageNumber: 1 } });
}
/*subnav fire --end*/
export async function getZipCode(params: any): Promise<any> {
  var result = await postJson("/api/firemap/fires/GetZipCodes", { ...params });
  return result ?? {};
}

export async function getEnginesDetails(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getEnginesDetails", {});
  return result ?? {};
}

export async function getTodaysFires(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getTodaysFires", {});
  return result ?? {};
}

export async function getYesterdaysFires(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getYesterdaysFires", {});
  return result ?? {};
}

export async function getTodaysMonitors(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getTodaysMonitors", {});
  return result ?? {};
}

export async function getYesterdaysMonitors(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getYesterdaysMonitors", {});
  return result ?? {};
}

export async function getTodaysThreats(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getTodaysThreats", {});
  return result ?? {};
}

export async function getYesterdaysThreats(): Promise<any> {
  var result = await postJson("/api/sitmap/fires/getYesterdaysThreats", {});
  return result ?? {};
}

/*sitmap  active fires --begin*/
export async function getActiveFires(params?: any): Promise<IActiveFire> {
  const { Filter } = params;
  const intUser = Filter.clientIds.length ? false : true;
  let allActiveFires: IActiveFire = {
    type: "FeatureCollection",
    features: [],
  };
  var allFires = await postJson(
    "/api/sitmap/fires/NewSitmapAllFires",
    { Filter: Filter } //debug: use { DateCreated: 2000 }
  );
  //Push all fires to activefire after grouping status
  let iIndex = 0;
  console.log(allFires);
  if (allFires && allFires.features) {
    var addedFireIds: any[] = [];
    for(const fire of allFires.features) {
      const isProgram = !!(fire.properties.wdsStatus || fire.properties.responseStatus);
      const isNoteworthy = !!fire.properties.noteworthy;
      const isSmokeCheckedButNoNotices = !!(!fire.properties.wdsStatus && fire.properties.responseStatus);

      if(!addedFireIds.includes(fire.properties.fireId)) {
        addedFireIds.push(fire.properties.fireId);

        allActiveFires.features?.push({
          ...fire,
          properties: {
            ...fire.properties,
            acreage: formatAcreageOrContained(fire.properties?.size),
            contained: formatAcreageOrContained(fire.properties?.containment),
            date: fire.properties?.updatedDate,
            program: isProgram,
            wdsStatus: isSmokeCheckedButNoNotices ? 2 : fire.properties?.wdsStatus,
            noteworthy: isNoteworthy,
            selected: false,
            isTop: false,
            clientStatus: [{id: fire.properties.clientId, status: fire.properties.wdsStatus}],
            cW: fire.properties.clientsWithin,
            pW: fire.properties.propertiesWithin
          },
          id: ++iIndex,
        });
      } else {
        var existingFireIndex = allActiveFires.features!.findIndex((f: any) => f.properties.fireId == fire.properties.fireId);
        allActiveFires.features![existingFireIndex].properties!.clientStatus = [...allActiveFires.features![existingFireIndex].properties!.clientStatus, {id: fire.properties.clientId, status: fire.properties.wdsStatus}];

        if(intUser) {
          allActiveFires.features![existingFireIndex].properties!.cW += fire.properties.clientsWithin;
          allActiveFires.features![existingFireIndex].properties!.pW += fire.properties.propertiesWithin;
        }
      }
    };
  }

  return allActiveFires;
}
const formatAcreageOrContained = (value: string | null) => {
  if (!value || isNaN(parseInt(value)) || parseInt(value) <= 0) return "";
  return value;
};
/*sitmap active fires --end*/

/*fire datatbale --begin*/
export interface ISortField {
  Name: string;
  IsDesc: boolean;
}
export interface IFireDataTableFilter {
  pagination?: {
    pageSize: number;
    pageNumber: number;
  };
  Filter: {
    FireId: number;
    ClientIds: number[] | undefined;
    WdsLob?: number;
    Address?: string;
    AlertBuffer?: any;
  };
  Sorting?: ISortField[];
}
export interface IFireDataTable {
  policyId: string;
  client: string;
  clientId: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  threatened: any;
  distance: any;
  geocode: string;
  enrolled: any;
  numberOfBuilding: number;
  prePlan: string;
  pid: number;
  isFull: boolean;
  rowTop: number;
  rowHeight: number;
  wdsLob: number;
  selected: boolean;
  isTop: boolean;
  mapNotEnrolledColor?: string;
  mapEnrolledColor?: string;
  status: string;
  rehabRequired: boolean;
  location: string;
  seqNum: string;
  callCount: number;
  exposure: number;
  producer: string;
  agencyName: string;
  agencyPhone: string;
  agencyCode: string;
  policies: string[];
}
export interface IFireDataTableRes {
  meta: {
    totalCount: number;
  };
  data: IFireDataTable[];
}

export interface IFireDataTableGroupFilter {
  Filter: {
    FireId: number;
    ClientIds: [];
    Address: string;
  };
}
export interface IFireDataTableGroup {
  addressCategory: string;
  count: number;
}

export async function getPolicyholders(params: IFireDataTableFilter): Promise<IFireDataTable[]> {
  const { Filter } = params;
  var result = await postJson("/api/firemap/fires/firedatatable", {
    Filter: Filter,
  });
  return result?.data;
}

export async function getFireDataTable(params: IFireDataTableFilter): Promise<IFireDataTableRes> {
  const { pagination, Filter, Sorting } = params;
  if (pagination) {
    if (isNaN(pagination.pageNumber) || pagination.pageNumber <= 0) {
      pagination.pageNumber = 1;
    }
    if (isNaN(pagination.pageSize) || pagination.pageSize <= 0) {
      pagination.pageSize = 10;
    }
  }
  return postJson("/api/sitmap/fires/firedatatable", {
    pagination: pagination,
    Filter: Filter,
    Sorting: Sorting && Sorting[0].Name.length === 0 ? null : Sorting,
  });
}
/*fire datatbale --end*/

export async function searchFireNames(filter: { [key: string]: string }): Promise<IProgramFire[]> {
  const data = { filter };
  return postJson("/api/SitMap/Fires/GetProgramedFires", data).then((res) => res?.data || []);
}

export async function getDispatchedFires(hours?: any): Promise<IFireName[]> {
  if (!hours) hours = "168";
  return postJson("/api/sitmap/fires/subnav", { Filter: { dateCreated: 3000 } });
}

/*firemap get fire perimeter --begin*/
export async function getPerimeterByFire(fireId: number): Promise<IFirePerimeter> {
  return getJSON("/api/firemap/fires/GetPerimeterByFire/" + fireId);
}

export async function getOldPerimeter(perimeterId: number): Promise<FeatureCollection> {
  const result = postJson("/api/firepage/firepage/getoldperimeter", perimeterId);
  return result;
}
/*firemap get fire perimeter --end*/

/*firemap engine table --begin*/
export async function getEnginesByFire(fireId: number): Promise<any> {
  return postJson("/api/firemap/fires/getenginesbyfire/", { Filter: { fireId } }).then((res) => res?.data);
}
/*firemap engine table --end*/

/*firemap kiptitle --begin*/
export async function getFireDetail(id: number): Promise<IFireDetail> {
  return getJSON("/api/firemap/fires/getfiredetails/" + id);
}
export async function saveKpiConfigration(configration: any) {
  postJsonWithNoTrack("/api/firemap/fires/SaveUserConfig", { configration });
}
export async function getKpiConfigration(): Promise<any> {
  return getJSON("/api/firemap/fires/GetUserConfig");
}
/*firemap kiptitle --end*/

export async function getClientByFireId(fireId: number): Promise<IClient[]> {
  return postJson("/api/sitmap/fires/getclients", { Filter: { fireId } }).then((res) => res?.data);
}

export async function getFireHistory(fireId: number): Promise<any> {
  return postJson("/api/firemap/fires/getfirehistory/", { Filter: { fireId } }).then((res) => res?.data);
}

export async function saveSmokeCheck(inputs: any): Promise<any> {
  await postJson("/api/notifications/fires/newfirenotice/", inputs).then((res) => res?.data);
  return await postJson("/api/sitmap/fires/savesmokecheckcomment/", inputs).then((res) => res?.data);
}
//When "smokecheck" button is clicked,
//step1: Call createNewFireNotice to generate NF,
//step2: Call FunctionApp to GIS analysis and insert property into res_triggered,
//step3: Call updateSmokeCheck update the smoke_check field.
//step4: Redirect to smokecheck page
export async function createNewFireNotice(inputs: any): Promise<any> {
  return postJsonWithNoTrack("/api/notifications/fires/newfirenotice/", inputs).then((res) => res.json());
}
export async function updateSmokeCheck(inputs: any): Promise<any> {
  postJson("/api/SmokeCheck/Teams/SendNotification", inputs.notification);
  return postJsonWithNoTrack("/api/sitmap/fires/savesmokecheckcomment/", inputs).then((res) => res.json());
}

/* #26481 [Fire Navigation][SitMap][Program Fire] Show "Smoke Check" and "Fire Overview" links on the details pop-up
/* #26492 [Fire Navigation][SitMap][Non-Program Fire] Show Fire Map link on the details pop-up  */
export async function checkFireNavigation(fireId: number): Promise<IFireNavigation> {
  return getJSON(`/api/common/fires/CheckFireNavigation/${fireId}`);
}

export async function getFireDetailsPanel(inputs: any): Promise<any> {
  return postJson("api/sitmap/fires/getFireDetailsPanel", inputs);
}

export async function getMyProperties(email: string | undefined): Promise<any> {
  let res = postJson("/api/sitmap/fires/getMyProperties", { email });
  return res;
}

export async function checkMGUProps10miles(id: number): Promise<any> {
  let res = getJSON("/api/sitmap/fires/checkForMGUProperties/" + id);
  return res;
}
